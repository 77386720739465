import React, { useEffect, useState } from 'react'
import { Tabs } from 'antd'
import type { TabsProps } from 'antd'
import threedApi from '@/api/threedApi'

const Datatopbox: React.FC<any> = (props) => {
  const { children } = props

  const [data, setData] = useState<any>({})

  useEffect(() => {
    getData()
  },[])

  const getData = () => {
    threedApi.getStatistics().then((res: any) => {
      if(res.code === 1) {
        setData(res.data)
      }
    })
  }

  return (
    <div className="threed-con">
      <div className="data-top-box">
        <div className="box-item f0">
          <i className="iconfont icon-a-3Dshuju i" />
          <span className="mlr10">3D模型</span>
          <span className="b">{data['3d_total']}</span>
        </div>
        <div className="box-item f1">
          <i className="iconfont icon-chexing i" />
          <span className="mlr10">关联车型</span>
          <span className="b">0</span>
        </div>
        <div className="box-item f2">
          <i className="iconfont icon-a-3Dshuju i" />
          <span className="mlr10">模型零件</span>
          <span className="b">{data.pid_count}</span>
        </div>
      </div>

      
    </div>
    
  )
}

export default Datatopbox