import React, { useState, useReducer, useEffect } from 'react'
import styles from './home.module.scss'
import { Pie } from '@ant-design/plots'
import { QuestionCircleOutlined, RightOutlined } from '@ant-design/icons'
import { Row, Col, Tooltip, Space, Spin } from 'antd'
import ranking_1 from '@/assets/imgs/ranking_1.png'
import ranking_2 from '@/assets/imgs/ranking_2.png' 
import ranking_3 from '@/assets/imgs/ranking_3.png'
import dataApi from '@/api/dataApi'

const Home: React.FC = () => {

  const [pieData, setPiedata] = useState<any>([
    {
      key: 'body',
      type: '车身',
      icon: 'icon-chexing',
      iconColor: '#08A728',
      bgColor: '#F2F7FF',
      value: 0
    },
    {
      key: 'chassis',
      type: '底盘',
      icon: 'icon-chexing',
      iconColor: '#FF7A00',
      bgColor: '#FFF9F3',
      value: 0
    },
    {
      key: 'car',
      type: '整车',
      icon: 'icon-chexing',
      iconColor: '#08A728',
      bgColor: '#EFFDF1',
      value: 0
    },
    {
      key: 'engine',
      type: '发动机',
      icon: 'icon-chexing',
      iconColor: '#D0BB03',
      bgColor: '#FAF9EC',
      value: 0
    },
  ])
  
  const [loading, setLoading] = useState<boolean>(false)
  const [change, setChange] = useState<boolean>(false)
  const [pietotal, setPietotal] = useState<number>(0)
  const config = {
    angleField: 'value',
    colorField: 'type',
    innerRadius: 0.6,
    width: 265,
    height: 180,
    label: {

      text: 'value',
      style: {
        fontWeight: 'bold',
      },
    },
    legend: {
      color: {
        title: false,
        position: 'bottom',
        rowPadding: 5,
      },
    },
    annotations: [
      {
        type: 'text',
        style: {
          text: pietotal + '',
          x: '50%',
          y: '50%',
          textAlign: 'center',
          fontSize: 12,
          // fontStyle: 'bold',
        },
      },
    ],
  };
  const labels = [
    {
      label: '车身'
    },
    {
      label: '底盘'
    },
    {
      label: '发动机'
    },
    {
      label: '整车'
    }
  ]

  useEffect(() => {
    getData()
  },[])

  const getData = () => {
    setLoading(true)
    dataApi.getHomeData().then((res: any) => {
      setLoading(false)
      if(res.code === 1) {
        let data: any = res.data
        let _pietotal: any = 0
        let _pieData = pieData.map((item: any) => {
          _pietotal += data[item.key]
          return {
            ...item,
            value: data[item.key]
          }
        })
        setPietotal(_pietotal)
        setPiedata(_pieData)
      }
    })
  }
  return (
    <Spin spinning={loading}>
    <div className={styles['home']}>
      <div className="flex-b-c" style={{ height: 240, width: '100%' }}  >
        <div style={{ width: '43%', height: '100%' }} className="bgc-fff br4 ptb10 plr15">
          <div className={styles['title-con'] + ' flex-s-c'}>
            模型数据统计
            <Tooltip
              title="已有模型数量"
            >
              <QuestionCircleOutlined className="pointer ml5" style={{ color: '#CCCCCC' }} />
            </Tooltip>
          </div>
          <div className="flex-s-c mt15">
            <div>
              {
                pieData.map((item: any, index: number) => {
                  return (
                    <div className={"flex-b-c plr10 ptb10 br4 " + `${index ? 'mt8' : ''}`} style={{ width: 180, background: item.bgColor }}>
                      <div className="flex-s-c">
                        <i className={"iconfont  mr5 " + item.icon} style={{ color: item.iconColor }} />
                        <span className="mr5">{item.type}</span>
                        <span>{item.value}</span>
                      </div>
                      <RightOutlined style={{ color: '#DDDDDD' }} />
                    </div>
                  )
                })
              }
            </div>
            <div className="flex-c-c ml20">
              <Pie {...config} data={pieData} />
            </div>
          </div>
        </div>
        <div style={{ width: '28%', height: '100%'  }} className="bgc-fff br4 ptb10 plr15">
          <div className={styles['title-con'] + ' flex-s-c'}>
            模型车系统计
            <Tooltip
              title="模型已关联车系数量"
            >
              <QuestionCircleOutlined className="pointer ml5" style={{ color: '#CCCCCC' }} />
            </Tooltip>
          </div>
          {
            labels.map((item: any, index: number) => {
              return (
                <div className="mt8">
                  <div className="flex-b-c  br4 bgc-f8 plr10 ptb10">
                    <div className="flex-s-c">
                      {
                        index >= 3 ? (
                          <span className="flex-c-c bgc-fff br4 mr10" style={{ height: 22, minWidth: 22 }}>{index + 1}</span>
                        ): (
                          <img className="mr10" src={index === 0 ? ranking_1 : index === 1 ? ranking_2 : index === 2 ? ranking_3 : ''} style={{ width: 22 }} alt=""/>
                        )
                      }
                      <span>{item.label}</span>
                    </div>
                    <div className="flex-s-c pointer">
                      <span className="mr5" style={{ color: '#888888' }}>{item.value || 0}</span>
                      <RightOutlined style={{ color: '#DDDDDD' }} />
                    </div>
                  </div>
                </div>
              )
            })
          }
          
        </div>
        <div style={{ width: '28%', height: '100%'  }} className="bgc-fff br4 ptb10 plr15">
          <div className={styles['title-con'] + ' flex-s-c'}>
            模型零件统计
            <Tooltip
              title="模型中零件数量"
            >
              <QuestionCircleOutlined className="pointer ml5" style={{ color: '#CCCCCC' }} />
            </Tooltip>
          </div>
          {
            labels.map((item: any, index: number) => {
              return (
                <div className="mt8">
                  <div className="flex-b-c  br4 bgc-f8 plr10 ptb10">
                    <div className="flex-s-c">
                      {
                        index >= 3 ? (
                          <span className="flex-c-c bgc-fff br4 mr10" style={{ height: 22, minWidth: 22 }}>{index + 1}</span>
                        ): (
                          <img className="mr10" src={index === 0 ? ranking_1 : index === 1 ? ranking_2 : index === 2 ? ranking_3 : ''} style={{ width: 22 }} alt=""/>
                        )
                      }
                      <span>{item.label}</span>
                    </div>
                    <div className="flex-s-c pointer">
                      <span className="mr5" style={{ color: '#888888' }}>{item.value || 0}</span>
                      <RightOutlined style={{ color: '#DDDDDD' }} />
                    </div>
                  </div>
                </div>
              )
            })
          }
        </div>
      </div>

      <div className="flex-b-c mt8" style={{ height: 460, width: '100%' }}>
        <div style={{ width: '33%', height: '100%'  }} className="bgc-fff br4 ptb10 plr15">
          <div className={styles['title-con'] + ' flex-s-c'}>
            标准底盘排行
            <Tooltip
              title="底盘标准数据数量"
            >
              <QuestionCircleOutlined className="pointer ml5" style={{ color: '#CCCCCC' }} />
            </Tooltip>
          </div>
          {
            [{label: '双叉臂 — 扭力梁'},{label: '麦弗逊 — 多连杆'},{label: '麦弗逊 — 扭力梁'},{label: '双叉臂 — 扭力梁'}].map((item: any, index: number) => {
              return (
                <div className="mt8">
                  <div className="flex-b-c  br4 bgc-f8 plr10 ptb10">
                    <div className="flex-s-c">
                      {
                        index >= 3 ? (
                          <span className="flex-c-c bgc-fff br4 mr10" style={{ height: 22, minWidth: 22 }}>{index + 1}</span>
                        ): (
                          <img className="mr10" src={index === 0 ? ranking_1 : index === 1 ? ranking_2 : index === 2 ? ranking_3 : ''} style={{ width: 22 }} alt=""/>
                        )
                      }
                      <span>{item.label}</span>
                    </div>
                    <div className="flex-s-c pointer">
                      <span className="mr5" style={{ color: '#888888' }}>{item.value || 0}</span>
                      <RightOutlined style={{ color: '#DDDDDD' }} />
                    </div>
                  </div>
                </div>
              )
            })
          }
        </div>
        <div style={{ width: '33%', height: '100%'  }} className="bgc-fff br4 ptb10 plr15">
          <div className={styles['title-con'] + ' flex-s-c'}>
            标准车系排行
            <Tooltip
              title="关联车型数量排行"
            >
              <QuestionCircleOutlined className="pointer ml5" style={{ color: '#CCCCCC' }} />
            </Tooltip>
          </div>
          {
            [{label: '奥迪 A4'},{label:'路虎 揽胜'},{label:'奥迪 A3 '},{label:'宝马 X1'},{label:'宝马 X3'}].map((item: any, index: number) => {
              return (
                <div className="mt8">
                  <div className="flex-b-c  br4 bgc-f8 plr10 ptb10">
                    <div className="flex-s-c">
                      {
                        index >= 3 ? (
                          <span className="flex-c-c bgc-fff br4 mr10" style={{ height: 22, minWidth: 22 }}>{index + 1}</span>
                        ): (
                          <img className="mr10" src={index === 0 ? ranking_1 : index === 1 ? ranking_2 : index === 2 ? ranking_3 : ''} style={{ width: 22 }} alt=""/>
                        )
                      }
                      <span>{item.label}</span>
                    </div>
                    <div className="flex-s-c pointer">
                      <span className="mr5" style={{ color: '#888888' }}>{item.value || 0}</span>
                      <RightOutlined style={{ color: '#DDDDDD' }} />
                    </div>
                  </div>
                </div>
              )
            })
          }
        </div>
        <div style={{ width: '33%', height: '100%'  }} className="bgc-fff br4 ptb10 plr15">
          <div className={styles['title-con'] + ' flex-s-c'}>
            标准发动机排行
            <Tooltip
              title="发动机标准数据数量"
            >
              <QuestionCircleOutlined className="pointer ml5" style={{ color: '#CCCCCC' }} />
            </Tooltip>
          </div>
          {
            [{label: '4 缸发动机'},{label: '6 缸发动机'},{label: '8 缸发动机'}].map((item: any, index: number) => {
              return (
                <div className="mt8">
                  <div className="flex-b-c  br4 bgc-f8 plr10 ptb10">
                    <div className="flex-s-c">
                      {
                        index >= 3 ? (
                          <span className="flex-c-c bgc-fff br4 mr10" style={{ height: 22, minWidth: 22 }}>{index + 1}</span>
                        ): (
                          <img className="mr10" src={index === 0 ? ranking_1 : index === 1 ? ranking_2 : index === 2 ? ranking_3 : ''} style={{ width: 22 }} alt=""/>
                        )
                      }
                      <span>{item.label}</span>
                    </div>
                    <div className="flex-s-c pointer">
                      <span className="mr5" style={{ color: '#888888' }}>{item.value || 0}</span>
                      <RightOutlined style={{ color: '#DDDDDD' }} />
                    </div>
                  </div>
                </div>
              )
            })
          }
        </div>
      </div>
    </div>
    </Spin>
  )
}

export default Home