//手机号格式check
export const checkMobile = (mobile: any) => {
  let mobileTest = /^[1][3,4,5,6,7,8,9][0-9]{9}$/
  if(mobileTest.test(mobile)) return true
  return false
}

//下载文件
export const downloadFile = (url: any) => {
  let a: any = document.createElement("a")
  a.setAttribute("download", true)
  a.href = url
  a.click()
  a.remove()
}

export function clearAllCookies() {
  var cookies = document.cookie.split(";");
  for (var i = 0; i < cookies.length; i++) {
    var cookie = cookies[i];
    var eqPos = cookie.indexOf("=");
    var name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;
    document.cookie = name + "=;expires=Thu, 01 Jan 1970 00:00:00 GMT";
  }
}

export const getSearchParams = (search: any) => {
	let _search = search.slice(1)
	let _searchObj: any = {}
	_search.split("&").forEach((item: any) => {
	  let _itemArr = item.split("=")
	  _searchObj[_itemArr[0]] = decodeURIComponent(_itemArr[1])
	})
	return _searchObj
}