import { message, Select, Spin, Modal, Popover, Switch } from 'antd'
import React, { useEffect, useRef, useContext, useState } from 'react'
import { useLocation } from 'react-router-dom'
import context from '@/context/MainContext'
import dataApi from '@/api/dataApi'
import styles from './index.module.scss'

let indexObj: any = {}

const headers = [
  {label: '品牌', key: 'brandCode'}, 
  {label: '车系', key: 'models'}, 
  {label: '年份', key: 'produced_year'}, 
  {label: '前悬架', key: 'front'}, 
  {label: '后悬架', key: 'rear'}, 
  {label: 'id', key: 'id'}, 
  {label: 'front_suspension_type', key: 'front_suspension_type'},
  {label: 'rear_suspension_type', key: 'rear_suspension_type'},
]

let editType: any = ''
let activePoint = {
    ri: 0,
    ci: 0
}

const HangExcel: React.FC<any> = (props) => {
  const { store } = useContext(context)
  const { brandsList, brandData, writeAuth } = store
  const { pathname } = useLocation()

  const [brandCode, setBrandCode] = useState<string>('audi')
  const [loading, setLoading] = useState<boolean>(false)

  const [visible, setVisible] = useState<any>(false)
  const [activeItem, setActiveItem] = useState<any>({}) 
  
  const [change, setChange] = useState<boolean>(false)
  let jexcelDivRef: any = useRef<HTMLDivElement>(null)
  let jexcelRef: any = useRef<any>({})

  const popoverOptions = editType === 'rear_suspension_type' ? ['多连杆', '扭力梁', '整体桥', '空气减震'] : ['麦弗逊', '双叉臂', '多连杆', '空气减震']

  useEffect(() => {
    initExcel()
  },[])

  useEffect(() => {
    getList()
  },[brandCode])

  


  const initExcel = async () => {

    let excel: any = document.getElementById(pathname)
    console.log(excel?.clientWidth)
    let width = excel?.clientWidth
    let height = excel?.clientHeight
    var saveIcon = 'data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBzdGFuZGFsb25lPSJubyI/PjwhRE9DVFlQRSBzdmcgUFVCTElDICItLy9XM0MvL0RURCBTVkcgMS4xLy9FTiIgImh0dHA6Ly93d3cudzMub3JnL0dyYXBoaWNzL1NWRy8xLjEvRFREL3N2ZzExLmR0ZCI+PHN2ZyB0PSIxNTc3MTc3MDkyOTg4IiBjbGFzcz0iaWNvbiIgdmlld0JveD0iMCAwIDEwMjQgMTAyNCIgdmVyc2lvbj0iMS4xIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHAtaWQ9IjI2NzgiIHdpZHRoPSIxOCIgaGVpZ2h0PSIxOCIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiPjxkZWZzPjxzdHlsZSB0eXBlPSJ0ZXh0L2NzcyI+PC9zdHlsZT48L2RlZnM+PHBhdGggZD0iTTIxMy4zMzMzMzMgMTI4aDU5Ny4zMzMzMzRhODUuMzMzMzMzIDg1LjMzMzMzMyAwIDAgMSA4NS4zMzMzMzMgODUuMzMzMzMzdjU5Ny4zMzMzMzRhODUuMzMzMzMzIDg1LjMzMzMzMyAwIDAgMS04NS4zMzMzMzMgODUuMzMzMzMzSDIxMy4zMzMzMzNhODUuMzMzMzMzIDg1LjMzMzMzMyAwIDAgMS04NS4zMzMzMzMtODUuMzMzMzMzVjIxMy4zMzMzMzNhODUuMzMzMzMzIDg1LjMzMzMzMyAwIDAgMSA4NS4zMzMzMzMtODUuMzMzMzMzeiBtMzY2LjkzMzMzNCAxMjhoMzQuMTMzMzMzYTI1LjYgMjUuNiAwIDAgMSAyNS42IDI1LjZ2MTE5LjQ2NjY2N2EyNS42IDI1LjYgMCAwIDEtMjUuNiAyNS42aC0zNC4xMzMzMzNhMjUuNiAyNS42IDAgMCAxLTI1LjYtMjUuNlYyODEuNmEyNS42IDI1LjYgMCAwIDEgMjUuNi0yNS42ek0yMTMuMzMzMzMzIDIxMy4zMzMzMzN2NTk3LjMzMzMzNGg1OTcuMzMzMzM0VjIxMy4zMzMzMzNIMjEzLjMzMzMzM3ogbTEyOCAwdjI1NmgzNDEuMzMzMzM0VjIxMy4zMzMzMzNoODUuMzMzMzMzdjI5OC42NjY2NjdhNDIuNjY2NjY3IDQyLjY2NjY2NyAwIDAgMS00Mi42NjY2NjcgNDIuNjY2NjY3SDI5OC42NjY2NjdhNDIuNjY2NjY3IDQyLjY2NjY2NyAwIDAgMS00Mi42NjY2NjctNDIuNjY2NjY3VjIxMy4zMzMzMzNoODUuMzMzMzMzek0yNTYgMjEzLjMzMzMzM2g4NS4zMzMzMzMtODUuMzMzMzMzeiBtNDI2LjY2NjY2NyAwaDg1LjMzMzMzMy04NS4zMzMzMzN6IG0wIDU5Ny4zMzMzMzR2LTEyOEgzNDEuMzMzMzMzdjEyOEgyNTZ2LTE3MC42NjY2NjdhNDIuNjY2NjY3IDQyLjY2NjY2NyAwIDAgMSA0Mi42NjY2NjctNDIuNjY2NjY3aDQyNi42NjY2NjZhNDIuNjY2NjY3IDQyLjY2NjY2NyAwIDAgMSA0Mi42NjY2NjcgNDIuNjY2NjY3djE3MC42NjY2NjdoLTg1LjMzMzMzM3ogbTg1LjMzMzMzMyAwaC04NS4zMzMzMzMgODUuMzMzMzMzek0zNDEuMzMzMzMzIDgxMC42NjY2NjdIMjU2aDg1LjMzMzMzM3oiIHAtaWQ9IjI2NzkiIGZpbGw9IiMyYzJjMmMiPjwvcGF0aD48L3N2Zz4='
    let options: any = {
      showGrid: true,
      showToolbar: true,
      showBottomBar: false,
      showContextmenu: true,
      mode: 'edit', //edit
      row: {
        len: 50000,
      },
      col: {
        len: headers.length - 3,
        width: 120
      },
      view: {
        height: ()=>{return height},
        width: ()=>{return width},
      },
      
    }
    
    jexcelRef.current = new (window as any).x_spreadsheet(jexcelDivRef.current, options)
    

    // jexcelRef.current.on('cell-edited',cellEdited)

    // jexcelRef.current.on('cell-selected', cellSelected)
    // jexcelRef.current.on("scrollbar-move", (e: any) => {
    //   console.log(e)
    // })
    // jexcelRef.current.on('menu-price', menuPriceClick)
    // jexcelRef.current.on('cells-selected', cellsSelected);
    console.log(jexcelRef.current)
    // jexcelRef.current.on('cell-edited', (e: any) => console.log(e))
    jexcelRef.current.on('cell-changed', (e: any) => console.log(e))
    // 编辑输入
    jexcelRef.current.on("cell-selected", (text: any, ri: any, ci: any) => {
      if(!writeAuth[pathname]) {
        message.warning("暂不支持编辑操作！")
        return false
      }
      console.log(text, ri, ci)
      if(ri && (ci === indexObj['front'] || ci === indexObj['rear'])) {
        let rowData = jexcelRef.current.datas[0].rows.getOrNew(ri).cells
        rowData = Object.values(rowData)
        console.log(rowData)
        let params: any = {
        }
        let keys: any = Object.keys(indexObj)
        rowData.forEach((it: any, i: number) => {
          let key = keys[i]
          params[key] = it.text
        })
        params['brandCode'] = brandCode
        if(params.id) {
          params.edit_id = params.id
          delete params.id
        }
        
        console.log(params)
        if(ci === indexObj['front']) {
            editType = "front_suspension_type"
        }else {
            editType = 'rear_suspension_type'
        }
        activePoint = {
            ri,
            ci
        }
        setActiveItem(params)
        setVisible(true)
      }
    })
    
    jexcelRef.current.validate()
    
    // getList()
  }
  const initData = async (list: any) => {
    let _rows: any = { len: list.length + 100 }
    let cells: any = []
    headers.forEach((item: any) => {
      cells.push({
        text: item.label,
        editable: false,
        style: 0
      })
    })
    _rows[0] = {
      cells
    }
    let selectStrs = ['front', 'rear']
    list.forEach((items: any, j: number) => {
      let cells: any = []
      headers.forEach((item: any, index: number) => {
        indexObj[item.key] = index
        if(item.key === 'tax_money') {
          cells.push({
            text: items['money'] - items['tax'] ? items['money'] - items['tax'] : '',
            editable: false
          })
        }else if(item.key === 'refund_num') {
          cells.push({
            text: items[item.key] ? items[item.key] : '',
            editable: false,
            style: 1
          })
        }else if(item.key === 'refund_money') {
          cells.push({
            text: items[item.key] ? items[item.key] : '',
            editable: false,
            style: 1
          })
        }else if(item.key === 'settled_money') {
          if(items['refund_money']) {
            cells.push({
              text: items[item.key] ? items[item.key] : '',
              editable: false,
              style: 1
            })
          }else {
            cells.push({
              text: items[item.key] ? items[item.key] : '',
              editable: false
            })
          }
        }else if(item.key === 'unsettled_money') {
          cells.push({
            text: items[item.key] ? items[item.key] : '',
            editable: false,
            style: 2
          })
        }else if(selectStrs.includes(item.key)) {
          cells.push({
            text: items[item.key] ? items[item.key] : '',
            editable: true,
            style: 2
          })
        }else if(item.key === 'brandCode') {
          let key = items[item.key]
          cells.push({
            text: key && brandData[key] ? brandData[key].name : '',
            editable: false,
            // style: 0
          })
        }else if(item.key === 'id') {
          cells.push({
            text: items[item.key] ? items[item.key] : '',
            editable: false,
            style: 0
          })
        }else {
          cells.push({
            text: items[item.key] ? items[item.key] : '',
            editable: true
          })
        }
      })
      _rows[j + 1] = {
        cells
      }
    })
    
    
    let excelDataObj = {
      name: '',
      merges: [],
      rows: _rows,
      autofilter: {
        filters: [],
        ref: 'B1:O1'
      },
      cols: {
        3: {
            width: 430
        },
        4: {
            width: 430
        },
        5: {
          hide: true
        },
        6: {
            hide: true
        },
        7: {
            hide: true
        }
      },
      freeze: "A2",
      styles: [
        {
          bgcolor: '#d8d8d8',
          font: {
            bold: true
          }
        },
        {
          color: '#ff0000'
        },
        {
          color: '#096BED'
        }
      ]
    }
    jexcelRef.current.loadData([excelDataObj])
  }
  const getList = () => {
    let params = {
      brandCode
    }
    setLoading(true)
    dataApi.getAllChassisList(params).then((res: any) => {
      setLoading(false)
      if(res.code === 1) {
        res.data.records.forEach((item: any) => {
            item.front = Object.values(item.front_suspension_type).filter((it: any) => it.status).map((itt: any) => {
                return itt.attribute + ' - ' + itt.standard_name
            })
            item.rear = Object.values(item.rear_suspension_type).filter((it: any) => it.status).map((itt: any) => {
                return itt.attribute + ' - ' + itt.standard_name
            })
        })
        
        initData(res.data.records)
      }
    })
  }

  const updateItems = (list: any) => {
    // let params = {
    //   edit_list: list
    // }
    // dataApi.updateItems(params).then((res: any) => {
    //   if(res.code === 1) {
    //     message.success("操作成功")
    //   }
    // })
  }

  const setItemValue = (items: any, key: string, value: any) => {
    console.log(items, key, value)
    items[key] = value
    let params = {
      mongo_id: activeItem.edit_id,
      [editType]: activeItem[editType]
    }
    let _text = Object.values(activeItem[editType]).filter((it: any) => it.status)
    let text = _text.map((it: any) => it.attribute + ' - ' + it.standard_name)
    dataApi.updateStdList(params).then((res: any) => {
      if(res.code === 1) {
        message.success("操作成功")
        let { ri, ci } = activePoint
        console.log(text)
        setChange(change => !change)
        let rowData = jexcelRef.current.datas[0].rows.getOrNew(ri).cells
        jexcelRef.current.datas[0].rows.setCell(ri, ci, {
            text,
            style: 2
        }, 'all')
        jexcelRef.current.datas[0].rows.setCell(ri, indexObj[editType], {
            text: activeItem[editType]
        }, 'all')
        jexcelRef.current.reRender()
      }
    })
  }
  return (
    <div className='p10 bgc-fff' >
      <div className='flex-s-c'>
      <span>筛选：</span>
        <Select
          className="mr12"
          style={{ width: 170 }}
          placeholder="品牌"
          showSearch
          filterOption={(input: any, option: any) =>
            (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
          }
          options={brandsList}
          value={brandCode || undefined}
          onChange={(value: any) => setBrandCode(value)}
        />
      </div>
      <Spin spinning={loading}>
        <div className='mt10' style={{ height: 'calc(100vh - 110px)' }} ref={jexcelDivRef} id={pathname}></div>
      </Spin>


      {
        visible ? (
          <Modal
            title={editType === 'rear_suspension_type' ? '后悬挂' : '前悬挂'}
            open={visible}
            onCancel={() => setVisible(false)}
            footer={null}
            maskClosable={false}
            width={600}
          > 
            <div className={styles['items']}>
              <div className={styles['items-head']}>
                <div style={{ width: '40%' }}>前悬架</div>
                <div style={{ width: '35%' }}>标准类型</div>
                <div style={{ width: '25%' }}>状态</div>
              </div>
              <div className={styles['items-body']}>
                {
                  activeItem[editType] ? Object.keys(activeItem[editType]).map((key: string) => {
                    let items = activeItem[editType][key]
                    return (
                      <div className={styles['item']}>
                        <div style={{ width: '40%' }}>{items.attribute}</div>
                        <div style={{ width: '35%' }}>
                          {items.standard_name}
                          <Popover 
                            placement="bottom" 
                            title={null} 
                            key={Math.random()}
                            content={
                              <div>
                                {
                                  popoverOptions.map((value: any, index: number) => {
                                    return <div className="pointer" style={{ margin: '8px 0' }} key={index} onClick={() => setItemValue(items, 'standard_name', value)}>{value}</div>
                                  })
                                }
                              </div>
                            } 
                            trigger="click"
                          >
                            <i className="iconfont icon-bianji pointer" style={{ color: '#206BC4', marginLeft: 5 }} />
                          </Popover>
                          
                        </div>
                        <div style={{ width: '25%' }}>
                          <Switch loading={items.loading ? true : false} checkedChildren="启用" unCheckedChildren="禁用" checked={items.status ? true : false} onClick={() => setItemValue(items, 'status', items.status ? 0 : 1)} />
                        </div>
                      </div>
                    )
                  }): null
                }
                
              </div>
            </div>

          </Modal>
        ): null
      }
    </div>
  )
}

export default HangExcel