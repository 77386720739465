import React, { Fragment, useEffect, useState, useContext } from 'react'
import styles from './index.module.scss'
import { Table, Select, Modal, Input, Button, Tabs, message, Popover } from 'antd'
import type { TabsProps } from 'antd'
import Datatopbox from './Components/Datatopbox'
import UploadModel from '@/components/UploadModel'
import Lookthreed from './Components/Lookthreed'
import { downloadFile } from '@/utils/utils'
import ThreedPart from './Components/ThreedPart'
import threedApi from '@/api/threedApi'
import context from '@/context/MainContext'
import { useLocation } from 'react-router-dom'


const Engine: React.FC<any> = (props) => {
  const { pathname } = useLocation()
  const { store, setStore } = useContext(context)
  const { writeAuth } = store
  const [pager, setPager] = useState<any>({
    page: 1,
    total: 0
  })
  const [pageSize, setPageSize] = useState<number>(10)
  const [open, setOpen] = useState<boolean>(false)
  const [currencyOpen, setCurrencyOpen] = useState<boolean>(false)
  const [threedOpen, setThreedOpen] = useState<boolean>(false)
  const [loading, setLoading] = useState<boolean>(false)
  const [submitLoading, setSubmitLoading] = useState<boolean>(false)
  const [ispreview, setIspreview] = useState<boolean>(false)
  const [list, setList] = useState<any>([])
  const [activeItem, setActiveItem] = useState<any>({})

  const [currencyData, setCurrencyData] = useState<any>({models: {}})
  
  
  const [searchData, setSearchData] = useState<any>({
    cylinders_num: '',
    engine_layout: '',
    isrelationModels: ''
  })

  const [activeKey, setActiveKey] = useState<'model' | 'part'>("model")

  useEffect(() => {
    if(currencyOpen) {
      let params = {
        cylinders_num: 'normal',
        engine_layout: 'normal'
      }
      threedApi.getEngineList(params).then((res: any) => {
        if(res.code === 1) {
          setCurrencyData(res.data.records && res.data.records[0] ? res.data.records[0] : {models: {}})
        }
      })
    }
  },[currencyOpen])
  
  useEffect(() => {
    getList()
  },[searchData.cylinders_num, searchData.engine_layout, searchData.isrelationModels, pager, pageSize])

  const getList = () => {
    let params = {
      ...searchData,
      page: pager.page,
      size: pageSize
    }
    setLoading(true)
    threedApi.getEngineList(params).then((res: any) => {
      setLoading(false)
      if(res.code === 1) {
        let _pager = res.data.page_data ? res.data.page_data : { page: 1, pagesize: 10, total: 0 }
        pager.total = _pager.total
        setList(res.data.records ? res.data.records : [])
      }
    })
    
  }

  const editItem = (item: any) => {
    setActiveItem({...item})
    setOpen(true)
  }
  const closeOpen = () => {
    setActiveItem({})
    setOpen(false)
  }

  const preview = (item: any) => {
    setActiveItem({...item})
    setThreedOpen(true)
  }
  const downLoad = (url: any) => {
    downloadFile(url)
  }

  const submit = (type?: string) => {
    let params = {
      ...activeItem
    }
    if(type) params = {...currencyData}
    if(params.id) {
      params.mongo_id = params.id
      delete params.id
    }
    setSubmitLoading(true)
    threedApi.operateEngineItem(params).then((res: any) => {
      setSubmitLoading(false)
      if(res.code === 1) {
        setCurrencyOpen(false)
        message.success("操作成功")
        closeOpen()
        getList()
      }
    })
  }

  const submitCurrency = () => {
    submit('currency')
  }

  const columns: any = [
    {
      title: '序号',
      dataIndex: 'id',
      align: 'center',
      width: 70,
      render: (text: any, record: any, index: number) => {
        return (
          <div className="flex-c-c">
            {pageSize * (pager.page - 1) + (index + 1)}
          </div>
        )
      }
    },
    {
      title: '燃油方式',
      dataIndex: 'fuel_type',
      align: 'center',
      
    },
    {
      title: '气缸数',
      dataIndex: 'cylinders_num',
      align: 'center'
    },
    {
      title: '布局',
      dataIndex: 'engine_layout',
      align: 'center'
    },
    // {
    //   title: '已关联车型',
    //   dataIndex: 'vin',
    //   align: 'center',
    //   render: (text: any, record: any, index: number) => {
    //     return (
    //       <div className="flex-c-c p-color">
    //         {text}
    //       </div>
    //     )
    //   }
    // },
    // {
    //   title: '大小 (MB)',
    //   dataIndex: 'id',
    //   align: 'center'
    // },
    {
      title: '操作',
      dataIndex: 'id',
      align: 'center',
      width: 220,
      render: (text: any, record: any, index: number) => {
        if(!writeAuth[pathname]) return null
        let models = record.models ? record.models : {}
        let notclick = !models.android || (models.android && !models.android.includes('.gltf')) ? true : false
        return (
          <div className="flex-c-c">
            <div className={"flex-s-c p-color pointer" + (notclick ? " no-click" : '')} onClick={() => !notclick && preview(record)}>
              <i className="iconfont icon-yulan fs14 mr2" />
              预览
            </div>
            {
              models.ios || models.android ? (
                <Popover
                  content={
                    <div>
                      {
                        models['ios'] ? (
                          <div className='pointer ptb5' onClick={() => downLoad(models['ios'])}>ios</div>
                        ): null
                      }
                      {
                        models['android'] ? (
                          <div className='pointer ptb5' onClick={() => downLoad(models['android'])}>android</div>
                        ): null
                      }
                    </div>
                  }
                >
                  <div className="flex-s-c p-color pointer mlr15">
                    <i className="iconfont icon-xiazai fs14 mr2" />
                    下载
                  </div>
                </Popover>
              ): (
                <div className="flex-s-c p-color pointer mlr15 no-click" >
                  <i className="iconfont icon-xiazai fs14 mr2" />
                  下载
                </div>
              )
            }
            <div className="flex-s-c p-color pointer" onClick={() => editItem(record)}>
              <i className="iconfont icon-bianji fs14  mr2" />
              修改
            </div>
          </div>
          
        )
      }
    },
  ]

  const items: TabsProps['items'] = [
    {
      key: 'model',
      label: '模型',
      children: (
        <Fragment>
          <div className="flex-b-c">
            <div className='flex-s-c'>
              <span>筛选：</span>
              <Select
                className="mr12"
                style={{ width: 170 }}
                placeholder="气缸数"
                options={['3','4','5','6','8','10','12'].map((it: any) => {
                  return {
                    label: it,
                    value: it
                  }
                })}
                allowClear
                value={searchData.cylinders_num || undefined}
                onChange={(value: any) => { pager.page = 1; setSearchData({...searchData, cylinders_num: value}) }}
              />
              <Select
                className="mr12"
                style={{ width: 170 }}
                placeholder="布局"
                options={'L型、V型、W型、H型、R型'.split('、').map((it: any) => {
                  return {
                    label: it,
                    value: it
                  }
                })}
                allowClear
                value={searchData.engine_layout || undefined}
                onChange={(value: any) => { pager.page = 1; setSearchData({...searchData, engine_layout: value}) }}
              />
              {/* <Select
                className="mr12"
                style={{ width: 170 }}
                placeholder="是否关联车型"
                allowClear
                value={searchData.isrelationModels || undefined}
                onChange={(value: any) => { pager.page = 1; setSearchData({...searchData, isrelationModels: value}) }}
              /> */}
              
            </div>
            {
              writeAuth[pathname] ? (
                <div>
                  <Button onClick={() => setCurrencyOpen(true)} className="mr15" type="primary" ghost>通用模型</Button>
                  <Button onClick={() => { setOpen(true); setActiveItem({models: {}}) }} type="primary" ghost icon={<i className="iconfont icon-guanlian" />}>添加模型</Button>
                </div>
              ): null
            }
          </div>
          <div className="mt10">
            <Table
              loading={loading}
              columns={columns}
              dataSource={list}
              pagination={{
                current: pager.page,
                total: pager.total,
                pageSize,
                showSizeChanger: true,
                onShowSizeChange: (current: any, size: any) => setPageSize(size),
                onChange: (page: any) => setPager({...pager, page}),
                showTotal: (total: number) => `共 ${total} 条`
              }}
            />
          </div>
        </Fragment>
      )
    },
    // {
    //   key: 'part',
    //   label: '零件',
    //   children: <ThreedPart/>
    // },
  ]
  return (
    <div >
      <Datatopbox

      />
      <div className="mt8 bgc-fff plr15 pb10 br4" style={{ minHeight: 'calc(100vh - 140px)' }}>
        <Tabs
          items={items}
          activeKey={activeKey}
          onChange={(value: any) => setActiveKey(value)}
        />
      </div>

      <Modal
        title={activeItem.id ? '修改模型' : "添加模型"}
        open={open}
        width={520}
        onCancel={() => closeOpen()}
        onOk={() => {
          submit()
        }}
        okButtonProps={{
          loading: submitLoading ? true : false
        }}
      >
        <div className="mtb20">
          <div className="flex-s-c mt10">
            <label className="right" style={{ width: 100 }}><span className='red'>* </span>燃油方式：</label>
            <Select
              style={{ flex: 1 }}
              placeholder="选择燃油方式"
              size="large"
              options={['汽油', '柴油'].map((it: any) => {
                return {
                  label: it,
                  value: it
                }
              })}
              value={activeItem.fuel_type}
              onChange={(value: any) => {
                setActiveItem({...activeItem, fuel_type: value})
              }}
            />
          </div>
          <div className="flex-s-c mt10">
            <label className="right" style={{ width: 100 }}><span className='red'>* </span>气缸数：</label>
            <Select
              style={{ flex: 1 }}
              placeholder="选择数量"
              size="large"
              options={['3','4','5','6','8','10','12'].map((it: any) => {
                return {
                  label: it,
                  value: it
                }
              })}
              value={activeItem.cylinders_num}
              onChange={(value: any) => {
                setActiveItem({...activeItem, cylinders_num: value})
              }}
            />
          </div>
          <div className="flex-s-c mt10">
            <label className="right" style={{ width: 100 }}><span className='red'>* </span>布局：</label>
            <Select
              style={{ flex: 1 }}
              placeholder="选择布局"
              size="large"
              options={'L型、V型、W型、H型、R型'.split('、').map((it: any) => {
                return {
                  label: it,
                  value: it
                }
              })}
              value={activeItem.engine_layout}
              onChange={(value: any) => {
                setActiveItem({...activeItem, engine_layout: value})
              }}
            />
          </div>
          <div className="flex-s-c mt10">
            <label className="right" style={{ width: 100 }}><span className='red'>* </span>模型：</label>
            <div className='flex-1 flex-b-c'>
              <UploadModel
                item={activeItem.models}
                lkey="ios"
                uploadLabel="上传IOS文件(加密zip)"
              />
              <UploadModel
                item={activeItem.models}
                lkey="android"
                uploadLabel="上传安卓文件(Gltf)"
              />
            </div>
          </div>
        </div>
      </Modal>

      <Modal
        title="通用模型"
        width={520}
        open={currencyOpen}
        onCancel={() => setCurrencyOpen(false)}
        onOk={() => submitCurrency()}
        okButtonProps={{
          loading: submitLoading ? true : false
        }}
      >
        <div className="mtb20">
          <div className='flex-s-c'>
            <label style={{ width: 100 }}>通用发动机：</label>
            <div className='flex-1 flex-b-c'>
              <UploadModel
                item={currencyData.models}
                lkey="ios"
                uploadLabel="上传IOS文件(加密zip)"
              />
              <UploadModel
                item={currencyData.models}
                lkey="android"
                uploadLabel="上传安卓文件(Gltf)"
              />
            </div>
          </div>
        </div>
      </Modal>

      {
        threedOpen ? (
          <Lookthreed
            title='预览'
            open={threedOpen}
            setOpen={(value: any) => setThreedOpen(value)}
            file={activeItem.models && activeItem.models.android ? activeItem.models.android : "https://bigfiles.007vin.com/3d/07114_Web_v2.gltf"}
          />
        ): null
      }

    </div>
  )
}

export default Engine