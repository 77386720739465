import React from 'react'
import ReactDOM from 'react-dom/client'
import { BrowserRouter } from 'react-router-dom'
import Main from '@/page/Main'
import reportWebVitals from './reportWebVitals'
import { ConfigProvider } from 'antd'
import zhCN from 'antd/locale/zh_CN'

import './index.scss'

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <BrowserRouter basename="/">
    <ConfigProvider
      locale={zhCN}
    >
      <Main />
    </ConfigProvider>
  </BrowserRouter>
  // <React.StrictMode>
  //   <Main />
  // </React.StrictMode>
  
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
