import { Navigate, useRoutes } from "react-router-dom"

import { Login } from '@/page/Account'
import Home from '@/page/Home'

import { Model, DatastandardEngine, ModelDetail, Hang, Part } from '@/page/Datastandard'

import Threedmodel, { Chassis, Wholevehicle, Carbody, ThreeEngine, Tpart } from '@/page/Threedmodel'
import { Repairdoc } from '@/page/Threedmodel/Components/ThreedPart'

import User, { Role, Permission, ButtonsList } from '@/page/User'
import Pagecommon from '@/page/Pagecommon'

const nav: any = [
	{
		path: "/",
		element: <Navigate to="/home"/>
  },
  {
		path: "/:conpath",
		element: <Pagecommon/>
	},
	{
		path: "/home",
		element: <Home />,
	},
	{
		path: "/login",
		element: <Login />,
  },

  // datastandard
  {
		path: "/datastandard/model",
		element: <Model />,
  },
  {
		path: "/datastandard/model/detail",
		element: <ModelDetail />,
  },
  {
		path: "/datastandard/engine",
		element: <DatastandardEngine />,
  },
  {
		path: "/datastandard/hang",
		element: <Hang />,
  },
  {
		path: "/datastandard/part",
		element: <Part />,
  },


  // threed
  {
		path: "/threed/:path",
		element: <Threedmodel />,
  },
  {
		path: "/threed/chassis",
		element: <Chassis />,
  },
  {
		path: "/threed/carbody",
		element: <Carbody />,
  },
  {
		path: "/threed/engine",
		element: <ThreeEngine />,
  },
  {
		path: "/threed/wholevehicle",
		element: <Wholevehicle />,
  },
  {
		path: "/threed/repairdoc",
		element: <Repairdoc />,
  },
	{
		path: '/threed/tpart',
		element: <Tpart />,
	},
  

  // user
  {
		path: "/usermanger/user",
		element: <User />,
  },
  {
		path: "/usermanger/role",
		element: <Role />,
  },
  {
		path: "/usermanger/permission",
		element: <Permission />,
  },
  {
		path: "/usermanger/buttonsList",
		element: <ButtonsList />,
  },
]

const Router = () => {
	const routes = useRoutes(nav)
  return routes
}

export default Router